.App {
  margin: 0;
}

#line-btn {
  background-color: #00b900 !important;
  border-color: #00b900 !important;
}

.form-label,
option {
  font-family: "Prompt", sans-serif !important;
}

.background {
  opacity: 0.05;
  position: fixed;
  z-index: -99;
  width: 100vw;
  height: 100vh;
  background-image: url("./assets/images/bg-min.png");
  background-repeat: repeat-y;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.chat-leftsidebar {
  background-color: transparent !important;
}

.number-huge {
  font-size: calc(1.375rem + 3vw) !important;
}

.account-pages {
  padding-top: 6rem !important;
  padding-bottom: 3rem !important;
}

.invite-subinfo {
  margin-left: 16px;
  display: flex;
  justify-content: space-between;
}

.invite-subinfo p {
  margin-bottom: 0.5rem;
}


.loading-container {
  text-align: center;
  margin-top: 128px;
}
.loading {
  display: inline-block;
  position: relative;
  width: 128px;
  height: 128px;
}
.loading div {
  position: absolute;
  background: #F7DE3A;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  animation-delay: -.7s;
}
@keyframes loading {
  0% {
    top: 60px;
    left: 60px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 128px;
    height: 128px;
    opacity: 0;
  }
}